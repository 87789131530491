<template>
	<div v-if="!loading">
		<b-tabs vertical pills nav-class="nav-left" nav-wrapper-class="col-md-2 col-12" content-class="col-12 col-md-10 mt-1 mt-md-0">
			<b-tab v-for="store in stores" :key="store.id" :title="store.name">
				<base-table :items="store.items" :headers="storeItems" table-top paginate>
					<template #cell(updated_at)="{ value }">
						{{ getProperDate(value) }}
					</template>
				</base-table>
			</b-tab>
		</b-tabs>
	</div>
	<div v-else class="">
		<div class="text-center text-primary mt-3 mb-2">
			<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
			<span class="d-block mt-1">Loading...</span>
		</div>
	</div>
</template>
<script>
import { BSpinner, BTabs, BTab } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import { getProperDate } from '@/utils/helpers';

export default {
	name: 'ProjectStores',

	components: { BSpinner, BTabs, BTab, BaseTable },

	data: () => ({
		stores: [],
		storeItems: [
			{ key: 'id', label: 'ID' },
			{ key: 'name', label: 'Item name' },
			{ key: 'unit' },
			{ key: 'quantity', label: 'quantity' },
			{ key: 'updated_at', label: 'Last update' }
		],

		loading: false
	}),

	async created() {
		this.loading = true;

		try {
			this.stores = await store.dispatch('projects/getProjectStores', this.$route.params.id);
			this.loading = false;
		} catch (error) {
			console.error(error);
			this.loading = false;
		}
	},

	methods: { getProperDate }
};
</script>

<style lang="scss" scoped></style>
